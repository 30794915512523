<template>
  <div>
    <ClientMatrix
      :asup-id="asupId"
      :only-errors="onlyErrors"
      :search="search"
      :time-range="timeRange"
      :default-rows-per-page="99999"
      :token="token"
      :full-height="false"
      :expand-rows="true"
    />
  </div>
</template>

<script>
import ClientMatrix from './components/ClientMatrix.vue'

export default {
  components: {
    ClientMatrix,
  },
  computed: {
    asupId() {
      return this.$route.params.id
    },
    onlyErrors() {
      return this.$route.query.onlyErrors === 'true'
    },
    search() {
      return this.$route.query.search
    },
    timeRange() {
      return {
        startDate: this.$route.query.start,
        endDate: this.$route.query.end,
      }
    },
    token() {
      return this.$route.query.token
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
